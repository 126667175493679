.logged-in{
    padding-top: 99px;
    @media screen and (max-width: 1600px){
        padding-top: 80px;
    }
}
main{
    padding: 70px 0;
    @media screen and (max-width: 1600px){
        padding: 30px 0;
    }
}
.hide {
    display: none;
}
.show{
    display: block;
}
.job-limit-message-color{
    color:#8b0000 !important;
}
.expand-toggle{
    font-size: 14px;
    color: $primary-color;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    padding-right: 47px;
    position: relative;
    cursor: pointer;
    &:after{
        content: '';
        width: 30px;
        height: 4px;
        background: $primary-color;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
    &.active{
        &:before{
            content: '';
            width: 4px;
            height: 30px;
            background: $primary-color;
            display: inline-block;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    @media screen and (max-width: 1860px){
        &:after{
            width: 25px;
            height: 3px;
        }
        &.active{
            &:before{
                height: 25px;
                width: 3px;
                right: 11px;
            }
        }
    }
}
.drag-drop-file{
    .card{
        border-radius: 10px;
        background: $dark-blck;
        border: none;
        padding: 60px;
        text-align: center;
        box-shadow: none;
        .card-body{
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0;
            .drag-drop-middle{
               min-width: 650px;
               outline: none !important;
               &:focus{
                outline: none;
                }
            }
            > div{
                outline: none;
            }
            &:focus{
                outline: none;
            }
            h3{
                color: rgba(34, 34, 34, 0.302);
                letter-spacing: 3.5px;
                text-transform: uppercase;
                margin: 0 0 24px;
                display: block;
                font-size: 34px;
                font-weight: normal;
                
            }
            .file-type{
                color: $secondary-font-color;
                letter-spacing:0.8px;
                font-size: 16px;
                display: block;
                margin-bottom: 40px;
                &.text-danger{
                    font-style: italic;
                   .error-icon{
                       width: 18px;
                       margin-right: 10px;
                       margin-top: -5px;
                       vertical-align: middle;
                   }
                }
            }
            .down-link{
                font-size: 16px;
                letter-spacing: 0.8px;
                color: $primary-color;
                margin-top: 40px;
                a{
                    color: $primary-color;
                    &:hover{
                        color: $secondary-font-color;
                    }
                }
            }
            .btn{
                min-width: 340px;
            }
            .file-progress{
                color: $secondary-font-color;
                letter-spacing:0.8px;
                font-size: 16px;
                display: block;
                margin-bottom: 40px;
                font-weight: 600;
                .file-icon{
                    display: block;
                    font-size: 46px;
                    color: $primary-color;
                    margin-bottom: 5px;
                    line-height: normal;
                    &.file-scanned-icon{
                        font-size: 58px;
                    }
                }
                .icon-cancel{
                    color: $primary-color;
                    font-size: 10px;
                    font-weight: 600;
                    margin-left: 10px;
                    cursor: pointer;
                }

                .progress{
                    height: 8px;
                    border-radius: 0;
                    background: #cccccc;
                    margin-top: 14px;
                    max-width: 580px;
                    margin: 14px auto 0;
                    .progress-bar{
                        background-image: linear-gradient(-90deg, #3c90a5, #62a6b7);
                    }
                }
            }

            &.successfully{
                h3{
                    color: $primary-color;
                    font-weight: 600;
                }
                .file-type{
                    font-size: 24px;
                }
            }

        }
    }
    @media screen and (max-width: 1600px){
        .card{
            padding: 30px;
            .card-body{
                h3{
                    font-size:26px;
                    margin: 0 0 20px;
                }
                .file-type{
                    margin-bottom: 20px;
                }
                .btn{
                    min-width: 270px;
                }
                .down-link{
                    margin-top: 30px;
                }
                .file-progress{
                    margin-bottom: 25px;
                }
                &.successfully{
                    
                    .file-type{
                        font-size: 20px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1199px){
        .card{
            .card-body{
                h3{
                    font-size: 26px;
                    margin: 0 0 15px;
                }
                .file-type{
                    margin-bottom: 30px;
                }
                .down-link{
                    margin-top: 30px;
                }
                
                .drag-drop-middle{
                    min-width: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 767px){
        .card{
            padding: 25px 20px;
            .card-body{
                h3{
                    font-size: 20px;
                    letter-spacing: 1px;
                }
                .file-type{
                    font-size: 14px;
                }
                .down-link{
                    font-size: 14px;
                }
                .btn{
                    min-width: 170px;
                }
            }
        }
    }
}


.jobs-btn{
    display: inline-block;
    .btn{
        &:first-child{
            border-top-right-radius:0;
            border-bottom-right-radius:0;
        }
        &:last-child{
            border-top-left-radius:0;
            border-bottom-left-radius:0;
        }
        &:hover{
            background: #297386 !important;
            color: $primary-font-color !important;
        }
    }
}

.create-job-form{
    textarea{
        height: 58px;
        resize: vertical;
    }
    .btn{
        min-width: 408px;
    }
    .info{
        position: absolute;
        top: 0;
        right: 0;
        i{
            color: $primary-color;
            font-size: 22px;
            cursor: pointer;
        }
    }
    .user-fill-name{
        list-style: none;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #dbdbdb;
        li{
            display: block;
            color: $primary-color;
            font-size: 18px;
            display: flex;
            margin-bottom: 20px;
            span{
                &:last-child{
                    font-weight: 700;
                    padding-left: 10px;
                }
            }
        }
    }
    .form-label-group{
        margin-bottom: 34px;
        .form-control{
            margin-bottom: 0;
        }
        .invalid-feedback{
            font-size: 18px;
            font-style: italic;
            letter-spacing: 1px;
            align-items: center;
            margin-top: 15px;
            img{
                width: 19px;
                margin-right: 10px;
              }
        }
        &.grade-check-label {
            .form-check-label {
                padding-right: 15px;
            }
            .form-check-input {
               margin-left: 0; 
            }
        }
    }
    
    @media screen and (max-width: 1860px){
        textarea{
            height: 50px;
        }
        // .col-md-4,.col-md-8{
        //     flex: 0 0 50%;
        //     max-width: 50%;
        // }
        .user-fill-name{
            li{
                font-size: 16px;
                margin-bottom: 15px;
            }
        }
        .info{
            i{
                font-size: 18px;
            }
        }
        .btn{
            min-width: 280px;
        }
    }
    @media screen and (max-width: 1600px){
        .user-fill-name{
            li{
                font-size: 13px;
                margin-bottom: 14px;
                &:last-child{
                    margin-bottom: 16px;
                }
            }
        }
        .form-label-group{
            margin-bottom: 28px;
            .invalid-feedback{
                font-size: 14px;
                margin-top: 10px;
                img{
                    width: 15px;
                    margin-right: 7px;
                  }
            }
        }
    }
    
}

.tooltip {
    left: -148px !important;
    max-width: 340px;
    &.show{
        opacity: 1;
    }
    .tooltip-inner{
        background: $primary-color;
        max-width: 350px;
        text-align: left;
        box-shadow: 0px 9px 14.28px 2.72px rgba(0, 0, 0, 0.08);
        padding: 12px 20px;
        font-family: $body-font-family;
        letter-spacing: 0.5px;
        font-size: 16px;
        border-radius: 6px;
        h4{
            color: $primary-font-color;
            font-size: 16px;
            margin-top: 8px;
        }
        @media screen and (max-width: 1860px){
            padding: 8px 12px;
            font-size: 14px;
            h4{
                font-size: 14px;
            }
        }
        @media screen and (max-width: 1600px){
            padding: 8px 12px;
            font-size: 13px;
            max-width: 370px;
            line-height: 18px;
            h4{
                font-size: 13px;
            }
        }
    }
    .arrow{
        width: 20px;
        height: 10px;
        bottom: -4px;
        margin-right: 10px;
        left: auto !important;
        right: 0;
    }
    &[x-placement^="top"]{
        top: -10px !important;
        .arrow{
            &:before {
                border-width:10px 10px 0;
                border-top-color: $primary-color;
                top: 3px;
            }
        }
    }
    &[x-placement^="bottom"]{
        top: 10px !important;
        .arrow{
            &:before {
                border-width:0 10px 10px;
                border-bottom-color: $primary-color;
                bottom: 4px;
            }
        }
    }
    
    @media screen and (max-width: 1860px){
        left: auto !important;
        margin-right: 7.5%;
    }
    @media screen and (max-width: 1600px){
        margin-right: 7.6%;
        max-width: 370px;
        .arrow{
            margin-right: 6px;
        }
        &[x-placement^="top"]{
            top: -2px !important;
            .arrow{
                &:before {
                    border-width:8px 8px 0;
                    border-top-color: $primary-color;
                    top: 3px;
                }
            }
        }
        &[x-placement^="bottom"]{
            top: 2px !important;
            .arrow{
                &:before {
                    border-width:0 8px 8px;
                    border-bottom-color: $primary-color;
                    bottom: 4px;
                }
            }
        }

    }
    @media screen and (max-width: 1400px){
        left: auto !important;
        margin-right: 6%;
    }
}

.min-btn{
    min-width: 368px;
    @media screen and (max-width: 1860px){
        min-width: 265px;
    }
}

.length-name{
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: $primary-color;
    .lenght-nb{
        font-size: 26px;
        font-weight: 700;
        margin-right: 10px;
    }
}

// search-job
.search-job-top{
    box-shadow: 0px 8px 7.2px 0.8px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    background: $primary-font-color;
    overflow: hidden;
    .list-of-name{
        display: flex;
        padding: 0;
        margin: 0;
        list-style: none;
        color: $primary-color;
        padding: 40px 64px;
        li{
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-right: 124px;
            &:last-child{
                margin-right: 0;
            }
            i{
                font-size: 40px;
                margin-right: 12px;
            }
            font-size: 16px;
            text-transform: uppercase;
            letter-spacing: 1px;
            .number{
                font-size: 26px;
                font-weight: 700;
                margin-left: 30px;
            }
        }
    }
    .btn-box{
        background: #fbfbfb;
        border-left: 2px solid $light-grey;
        padding: 40px 64px;
        display: inline-block;
    }
    @media screen and (max-width: 1860px){
        .list-of-name{
            padding: 30px;
            li{
                margin-right: 50px;
                .number{
                    margin-left: 18px;
                    font-size: 24px;
                }
            }
        }
        .btn-box{
            padding: 30px;
        }
    }
    @media screen and (max-width: 1600px){
        .list-of-name{
            padding: 20px 30px;
        }
        .btn-box{
            padding: 20px 30px;
        }
    }
}

.from-to{
    display: flex;
    flex-direction: column;
    .from-row{
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 12px;
        span{
            font-size: 18px;
            color: $secondary-font-color;
            margin-right: 2px;
            min-width: 45px;
        }
        .select-custom{
            margin-left: 6px;
            
            .form-control,.form-control__control{
                margin: 0;
                min-width: 165px;
            }
            &.to-range{
                .form-control,.form-control__control{
                    min-width: 116px;
                }
            }
            &:after{
                right: 37px;
            }
        }
    }

    // &.search-from-to{
    //     @media screen and (max-width: 1600px){
    //         .from-row{
    //             flex-wrap: wrap;
    //              align-items: flex-start;
    //              margin-bottom: 6px;
    //              .select-custom{
    //                  margin: 5px 0;
    //                  width: 100%;
    //              }
    //         }
    //     }
    // }

    @media screen and (max-width: 1860px){
        .from-row{
            span{
                font-size: 16px;
                min-width: 34px;
            }
        }
    }
    @media screen and (max-width: 1600px){
        .from-row{
            span{
                font-size: 14px;
            }
        }
        &.search-from-to{
            .from-row{
                span{
                    min-width: 34px;
                }
            }
        }
    }
}
.react-datepicker-wrapper{
    width: 100%;
}


.search-bottom{
    max-width: 83%;
    margin: 20px 0 0 30px;
}
.search-id{
    max-width: 95%;
}
// .col-full{
//     @media screen and (max-width: 1640px){
//         flex: 0 0 100%;
//         max-width: 100%;
//     }
// }
.search-sub-btn{
    min-width: 80%;
    @media screen and (max-width: 1600px){
        min-width: 305px;
    }
}
.mill-machine{
    .select-custom{
        .unselect {
            opacity: 0.8;
        }
        .form-control__control{
            font-size: 15px;
            letter-spacing: normal;
            
            @media screen and (max-width: 1600px){
                padding-left: 10px;
                font-size: 14px;
                line-height: normal;
            }
        }
    }
}

// modal 
.modal {
    .modal-content{
        border-radius: 16px;
        border: none;
        overflow: hidden;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
        .modal-header{
            background: #fafafa;
            padding: 25px 30px;
            text-align: center;
            color: $primary-color;
            justify-content: center;
            position: relative;
            border-bottom-color: $light-grey;
            h5{
                font-weight: normal;
                text-transform: uppercase;
                color: $primary-color;
                letter-spacing: 1px;
            }
            .close{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 14px;
                color: $primary-color;
                opacity: 1;
                font-size: 32px;
                width: 40px;
                padding: 0;
                height: 40px;
                display: inline-block;
                margin: 0;
                font-weight: 300;
            }
        }
        .modal-footer{
            background: #fafafa;
            padding: 20px 58px;
            border-top-color: $light-grey;
            .row{
                width: 100%;
            }
            .show-result{
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            .btn{
                font-size: 12px;
                width: 230px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                i{
                    font-size: 10px;
                }
            }
        }
        .modal-body{
            padding: 50px 74px;
        }
    }

    @media screen and (max-width: 1600px){
        .modal-content{
            .modal-header{
                padding: 12px 30px;
                h5{
                    font-size: 18px;
                }
            }
            .modal-body{
                padding: 30px 35px;
            }
            .modal-footer{
                padding: 12px 15px;
                .btn{
                    width: 200px;
                }
            }
        }
    }
}

.filter-table-modal{
    .filter-table-user-select{
        justify-content: space-between;
        .select-custom{
            min-width: 300px;
        }
    }
    .filter-table-user{
        position: relative;
        z-index: 9999;
        &:nth-child(2){
            min-width: 310px;
        }
    }
    .user-select{
        display: flex;
        .form-control--is-disabled{
            position: relative;
        }
        .form-control__control{
            margin: 0 0 -10px;
        }
    }
}

.success-modal{
    .modal-content{
        text-align: center;
        .modal-body{
            padding: 60px 74px;
        }
        h2{
            justify-content: center;
            font-weight: 700;
            margin-bottom: 22px;
            font-size: 34px;
        }
        p{
            margin: 0 0 15px;
            font-size: 24px;
            letter-spacing: 1px;
        }
        .modal-body span{
            margin: 0 0 15px;
            color: #dc3545 !important; 
            font-size: 17.5px;
            letter-spacing: 1px;
        }
   }
}

.share-job-modal{
    textarea{
        min-height: 187px;
    }
    .invalid-feedback{
        font-style: italic;
        letter-spacing: 1px;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;
        span {
            color: #dc3545 !important; 
            font-size: 18px;
        }
        img{
            width: 19px;
            margin-right: 10px;
            margin-top: -7px;
          }
    }
    &.modal-xl{
        @media screen and (min-width: 1340px){
            max-width: 1300px;
        }
    }
    .btn{
        .icon-email{
            font-size: 12px !important;
        }
    }
    .form-label-group{
        &.error-invalid{
            .form-control {
                margin: 0;
            }
        }
    }
    .modal-footer{
        .btn{
            i{
                &.icon-email-light{
                    font-size: 11px;
                }
            }
        }
    }
    @media screen and (max-width: 1600px){
        textarea{
            min-height: 170px;
        }
        &.modal-xl{
            max-width: 990px;
        }
        .invalid-feedback{
            span {
                font-size: 15px;
            }
            img{
                width: 16px;
                margin-right: 6px;
                margin-top: -7px;
              }
        }
    }
}

.file-attch-link{
    a{
        color: $primary-color;
        font-size: 18px;
        letter-spacing: 0.5px;
        i{
            margin-right: 10px;
            font-size: 22px;
        }
        @media screen and (max-width: 1600px){
            font-size: 15px;
        }
    }
}

.material-block{
    display: flex;
    padding-top: 50px;
    .icon{
        font-size: 100px;
        color: $primary-color;
        line-height: normal;
    }
    .info{
        padding-left: 44px;
        h3{
            font-size: 24px;
            letter-spacing: 0.6px;
        }
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                font-size: 18px;
                letter-spacing: 0.5px;
                margin-bottom: 20px;
                a{
                    color: $primary-color;
                    &:hover{
                        opacity: 0.7;
                    }
                }
            }
        }
        p{
            color: $lightFontColor;
            letter-spacing: 0.6px;
            line-height: normal;
            margin: 0;
        }
    }
    @media screen and (max-width: 1600px){
        padding-top: 30px;
        .icon{
            font-size: 60px;
        }
        .info{
            padding-left: 25px;
            h3{
                font-size: 18px;
                margin-bottom: 14px !important;
            }
            ul{
                li{
                    font-size: 15px;
                    margin-bottom: 8px;
                }
            }
            p{
                font-size: 14px;
            }
        }
    }
}

.icon-btn{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 9px 14.28px 2.72px rgba(0, 0, 0, 0.2);
    color: $primary-color;
    cursor: pointer;
    transition: $transition-animate;
    i{
        font-size: 15px;
    }
    &:hover{
        background: $primary-color;
        color: $primary-font-color;
    }
    &.danger-icon{
        background: #e32645;
        color: $primary-font-color;
        &:hover{
            color: #e32645;
            background: $primary-font-color;
        }
    }
}

.job-process-bar{
    padding: 50px 70px;
    h2{
        font-weight: 700;
        text-transform: none;
        letter-spacing: 1px;
        font-size: 28px;
        color: $primary-color;
    }
    .job-top-link{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            display: inline-block;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 1.5px;
            margin-left: 30px;
            position: relative;
            padding: 8px 0 8px 30px;
            vertical-align: middle;
            &:before{
                content: '';
                height: 100%;
                width: 2px;
                background: #ebebeb;
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
            }
            &:first-child{
                padding: 0;
                margin: 0;
                &:before{
                    display: none;
                }
            }
            a{
                color: $primary-color;
                cursor: pointer;
                i{
                    margin-left:5px;
                }
                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }

    .process-circle{
        max-width: 344px;
        margin: 0 auto;
        text-align: center;
        .percentage{
            font-size: 48px;
            font-weight: 700;
            color: $primary-color;
            display: block;
            .icon-completed{
                font-size: 64px;
                margin-bottom: 20px;
                display: inline-block;
            }
        }
        .icon-btn{
            width: 58px;
            height: 58px;
            i{
                font-size: 20px;
            }
        }
        .text{
            font-size: 20px;
            font-weight: 700;
            color: $secondary-color;
            letter-spacing: 0.7px;
            display: block;
        }
        .CircularProgressbar-path {
            stroke: $primary-color;
            stroke-width: 4px;
            border-radius: 0;
          }
          .CircularProgressbar-trail {
            stroke: #ebebeb;
            stroke-width: 4px;
          }
    }

    .process-list-info{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            display: flex;
            margin-top: 28px;
            align-items: center;
            &:first-child{
                margin-top: 0;
            }
            span{
                &:first-child{
                    color: #888888;
                    font-size: 16px;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 0.8px;
                    min-width: 120px;
                    margin-right: 10px;
                }
                &.data{
                    font-size: 20px;
                    letter-spacing: 0.6px;
                    width: 100%;
                    position: relative;
                    &.active{
                        color: $primary-color;
                    }
                    .edit{
                        color: $primary-color;
                        position: absolute;
                        right: 0;
                        font-size: 16px;
                        cursor: pointer;
                        top: 50%;
                        transform: translateY(-50%);
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover{
                        .edit{
                        opacity: 1;
                        visibility: visible;
                        }
                    }

                    input{
                        background:$light-grey;
                        border: none;
                        margin: 0;
                        padding: 6px 12px;
                        width: 100%;
                        border-radius: 4px;
                    }
                }
            }
        }
    }
    .file-attch-link{
        a{
            font-size: 20px;
            i{
                font-size: 40px;
                vertical-align: middle;
            }
        }
    }
    h5{
        color: #888888;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        min-width: 120px;
        margin-bottom: 15px;
    }
    p{
        color: $lightFontColor;
        font-size: 18px;
        letter-spacing: 0.6px;
        margin-bottom: 0;
    }
    .notes{
        display: block;
        position: relative;
        .edit{
            color: $primary-color;
            position: absolute;
            right: 0;
            font-size: 16px;
            cursor: pointer;
            top: 2px;
            opacity: 0;
            visibility: hidden;
        }
        &:hover{
            .edit{
            opacity: 1;
            visibility: visible;
            }
        }
        .text-area-edit{
            background: #f0f0f0;
            border: none;
            margin: 0;
            padding: 6px 12px;
            width: 100%;
            border-radius: 4px;
            color: $lightFontColor;
            font-size: 18px;
            letter-spacing: 0.6px;
            margin-bottom: 0;
            resize: none;
            &:focus{
                outline: none;
            }
        }
    }
    @media screen and (max-width: 1600px){
        padding: 35px 40px;
        h2{
            font-size: 22px;
        }
        .job-top-link{
            li{
                font-size: 14px;
            }
        }
        .process-list-info{
            li{
                span{
                    &:first-child{
                        font-size: 14px;
                        min-width: 100px;
                    }
                    &.data{
                        font-size: 16px;
                    }
                }
            }
        }
        .file-attch-link{
            a{
                font-size: 16px;
                i{
                    font-size: 30px;
                    vertical-align: middle;
                }
            }
        }
        h5{
            font-size: 15px;
        }
        p{
            font-size: 16px;
            line-height: 28px;
        }
    }
}
.filters-add-main{
    margin-top: 30px;
    .filters-add{
        font-size: 14px;
        font-weight: 700;
        color: #777777;
        .filter-tags{
            color: $secondary-color;
            font-size: 16px;
            letter-spacing: 0.6px;
            border-radius: 16px;
            background: #e0e0e0;
            padding: 4px 18px;
            display: inline-block;
            margin: 3px 0;
            span {
                font-weight: 300;
                margin-left: 5px;
                font-size: 18px;
                cursor: pointer;
            }
            &.expand-tag{
                cursor: pointer;
            }
        }
    }
    @media screen and (max-width: 1600px){
        margin-top: 5px;
        .filters-add{
            .filter-tags{
                font-size: 14px;
                line-height: normal;
                padding: 3px 18px 5px 18px;
            }
        }
    }

    .clear-all-tag{
        text-transform: uppercase;
        color: $primary-color;
        letter-spacing: 0.5px;
        cursor: pointer;
        white-space: nowrap;
    }
}
.filter-modal {
    .invalid-feedback{
        font-style: italic;
        letter-spacing: 1px;
        align-items: center;
        span {
            color: #dc3545 !important; 
            font-size: 17.5px;
        }
        img{
            width: 19px;
            margin-right: 10px;
          }
    }
    .filter-secound-row{
        margin-top: 55px;
    }
  
    @media screen and (max-width: 1600px){
        max-width: 990px;
        .invalid-feedback{
            span {
                font-size: 14px;
            }
            img{
                width: 16px;
            }
        }
        .filter-secound-row{
            margin-top: 30px;
        }
        .from-to {
            .from-row {
                .select-custom{
                    .react-datepicker-wrapper{
                        .form-control{
                            min-width: 145px;
                        }
                    }
                    &.to-range{
                        .react-datepicker-wrapper{
                            .form-control{
                                min-width: 124px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.search-form {
    .data-range{
        padding-right: 50px;
        .from-to {
            .from-row {
                .select-custom {
                    .form-control,.form-control__control {
                        min-width: 145px;
                        letter-spacing: normal;
                        padding-left: 10px;
                        @media screen and (max-width: 1860px){
                            min-width: 125px;
                            padding-left: 10px;
                        }
                        @media screen and (max-width: 1400px){
                            padding-left: 8px;
                        }
                    }
                    &.to-range{
                        .form-control,.form-control__control {
                            min-width: 110px;
                        }
                    }
                }
            }
        }
    }
    .invalid-feedback{
        font-style: italic;
        letter-spacing: 1px;
        align-items: center;
        span {
            color: #dc3545 !important; 
            font-size: 17px;
        }
        img{
            width: 19px;
            margin-right: 10px;
          }
    }
    .clear-all-tag{
        text-transform: uppercase;
        color: $primary-color;
        letter-spacing: 0.5px;
        cursor: pointer;
        white-space: nowrap;
    }
    @media screen and (max-width: 1860px){
        .data-range{
            padding-right: 40px;
        }
    }
    @media screen and (max-width: 1600px){
        .data-range{
            padding-right: 0;
        }
        .invalid-feedback{
            span {
                font-size: 14px;
            }
            img{
                width: 16px;
            }
        }
    }
}


.accordian-chart-head{
    display: block;
    border-radius: 10px;
    box-shadow: 0px 8px 7.2px 0.8px rgba(0, 0, 0, 0.06);
    background: $primary-font-color;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .accordian-chart-head-left{
        width: calc(100% - 80px);
        .head-chart-content{
            display: flex;
            .chart-img{
                width: 580px;
                border-right: 1px solid $light-grey;
                align-items: center;
                display: flex;
            }
            .chart-info-title{
                padding: 34px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: calc(100% - 580px);
                h3{
                    font-weight: 700;
                    font-size: 24px;
                    margin-bottom: 30px;
                    letter-spacing: 0.5px;
                }
                p{
                    color: $secondary-font-color;
                    letter-spacing: 0.4px;
                    margin: 0;
                }
            }
        }

        .head-chart-open-content{
            display: flex;
            background: #fafafa;
            padding: 40px 0 30px 0;
            .open-title{
                padding: 0 100px;
                width: 680px;
                h3{
                    color: $primary-color;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 38px;
                    margin: 0;
                    &:after{
                        content: '';
                        display: block;
                        width: 160px;
                        height: 1px;
                        background: $primary-color;
                        margin: 20px 0 0;
                    }
                }
            }
            .open-info-title{
                h4{
                    color: $secondary-font-color;
                    text-transform: uppercase;
                    font-size: 18px;
                    margin-bottom: 10px;
                    letter-spacing: 0.35px;
                }
                p{
                    font-size: 18px;
                    color: $lightFontColor;
                    line-height: 40px;
                    letter-spacing: 0.35px;
                    margin: 0;
                }
            }
        }

        @media screen and (max-width: 1600px){
            .head-chart-content{
                align-items: inherit;
                .chart-img{
                    width: 440px;
                }
                .chart-info-title{
                    width: calc(100% - 440px);
                    padding: 24px;
                    h3{
                        font-size: 20px;
                        margin-bottom: 15px;
                    }
                    p{
                        line-height: 26px;
                        font-size: 15px;
                    }
                }
            }

            .head-chart-open-content{
                padding: 20px 0 20px 0;
                .open-title{
                    padding: 0 40px;
                    width: 520px;
                    h3{
                        font-size: 20px;
                        line-height: 32px;
                    }
                   
                }
                .open-info-title{
                    p{
                        line-height: 32px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .toggle-btn{
        background: #fafafa;
        border-left: 1px solid $light-grey;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        position: relative;
        .btn{
            width: 40px;
            height: 40px;
            padding: 0;
            font-weight: normal;
            font-size: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $secondary-font-color;
            &:before{
                content: '+';
                margin-left: 3px;
            }
            &:hover{
                color: $primary-font-color;
            }
        }
    }
    &.active{
        border-radius: 10px 10px 0 0;
        position: relative;
        z-index: 1;
        .toggle-btn{
            background: $primary-font-color;
            .btn{
                background: $primary-color;
                color: $primary-font-color;
                box-shadow: 0px 9px 14.28px 2.72px rgba(0, 0, 0, 0.2);
                &:before{
                    content: '';
                    width: 11px;
                    height: 2px;
                    background: $primary-font-color;
                    margin-left: 0;
                }
            }
            &:after{
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 0;
                margin: 0 auto;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid $primary-color;
            }
        }
    }
}

.accordian-chart-middle{
    .card{
        border-radius: 0 0 10px 10px;
        .density-plot{      
            path.highcharts-point{
                display: none;
            }
        }
        .correlation-heatmap{
            .highcharts-legend-nav-inactive{
                display: none;
            }
            .highcharts-legend-navigation{
                display: none;
            }
        }
        .prediction-system{
            .highcharts-legend-box{
                width: 270px !important;
            }
            .highcharts-legend-item{
                text{
                    font-weight: normal !important;
                    color: $lightFontColor !important;
                    fill: $lightFontColor !important;
                    font-size: 14px !important;
                    text-transform: uppercase;
                }
                .highcharts-point{
                    height: 1px !important;
                    width: 17px !important;
                    transform: translateY(6px) translateX(-6px);
                }
            }
        }  
    }
}
table{
    &.table-chart{
        margin: 0;
        .th-chart{
            width: 300px;
        }
        thead{
            th{
                color: #898989;
            }
            
        }
        tbody {
            td{
                color: $secondary-font-color;
                font-weight: 600;
                font-size:14px ;
                position: relative;
                .highcharts-container {
                    font-weight: normal;
                }
                .stock-had-line{
                    padding-left: 40px;
                    color: #000;
                    font-size: 16px;
                    letter-spacing: 1px;
                }
                @media screen and (max-width: 1600px){
                    .stock-had-line{
                        font-size: 14px;
                        padding-left: 20px;
                    }
                }
            }
        }
        .icon-zoom{
            position: absolute;
            top: 12px;
            right: 15px;
            background:$primary-font-color;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
            color: $primary-color;
            z-index: 1;
            box-shadow: 0px 9px 14.28px 2.72px rgba(0, 0, 0, 0.08);
        }
        tfoot{
            background: $lightGreyTwo;
            .btn {
                i{
                    font-size: 20px;
                    margin-left: 28px;
                    position: relative;
                    top: 2px;
                }
                .first-icon{
                    margin: 0 28px 0 0;
                }
                letter-spacing: 2.7px;
                min-width: 600px;
                margin: 2px auto;
            }
            @media screen and (max-width: 1600px){
                .btn {
                    min-width: 400px;
                    i{
                        font-size: 16px;
                        margin-left: 18px;
                    }
                    .first-icon{
                        margin: 0 18px 0 0;
                    }
                }
            }
        }
    }
}