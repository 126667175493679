.master-grade-select {
    button {
      min-width: 0;
      padding: 10px 15px;
      height: auto;
      line-height: normal;
      border-radius: 5px;
      margin-top: 8px;
      font-size: 12px;
      letter-spacing: 1px;
      min-width: 0 !important;
    }
  
    .list-group-wrap {
      max-height: 200px;
      margin-bottom: 10px;
      .list-group-wrapper {
        background-color: #fff;
  
        .grade-group-item {
          position: relative;
          border-bottom: 1px solid rgba(0, 0, 0, 0.125);
          .grade-items-wrap {
            display: flex;
            .list-group {
              flex: 1;
              border-radius: 0;
              &.list-group-model {
                border-left: 1px solid rgba(0, 0, 0, 0.125);
                padding-right: 30px;
              }
            }
          }
          .remove-item {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 16px;
            color: red;
            cursor: pointer;
            height: 100%;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
      .list-group {
        .list-group-item {
          border-radius: 0;
          padding: 6px;
          border: 0 none;
          flex: auto;
          & span {
            background-color: rgba(0, 0, 0, 0.07);
            padding: 5px;
            margin-right: 5px;
            font-size: 12px;
            margin: 2px 2px 2px 0;
            display: inline-block;
          }
          .remove-item {
            font-size: 16px;
            color: red;
            cursor: pointer;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 480px) {
    .mpl-2 {
      padding-left: 5px !important;
    }
    .mpr-2 {
      padding-right: 5px !important;
    }
    .master-grade-select {
      .list-group-wrap .list-group {
        .list-group-item span {
          font-size: 10px;
        }
      }
    }
  }
  