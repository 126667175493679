*{box-sizing: border-box;}
body{
    font-size:14px;
    color:$secondary-font-color;
    margin: 0;
    padding: 0;
    font-family: $body-font-family;
    font-weight: normal;
    background: $body-bg;
    overflow-x: hidden;
}

a,.btn-sub,button{
    transition: $transition-animate;
    outline: 0;
}
a,ul,li{
    text-decoration:none;
}
a{
    color: $primary-color;
}
a{
    &:hover,&:active,&:focus{
        text-decoration:none;outline: none;
    }
    img{
        border:0px;
    }
    &:hover{
        color: $primary-color;
    }
}
button:focus{
    outline: none;
}
img{
    max-width: 100%;
}
section{
    width: 100%;
    display: inline-block;
    position: relative;
    
}
.section-space{
    padding: 80px 0;
    @media screen and (max-width: 991px){
        padding: 65px 0;
    }
    @media screen and (max-width: 575px){
        padding: 50px 0;
    }
}
.container {
    // @media (min-width: 1400px){
    //     max-width: 1320px;
    // }
    max-width: 85%;
     @media (max-width: 1400px){
        max-width: 90%;
    }
    // @media (min-width: 1200px){
    //     max-width: calc(100% - 140px);
    // }
    // @media (min-width: 1800px){
    //     max-width: 1860px;
    // }
}
/*general style*/
p{
    color: $secondary-font-color;
    font-size: 16px;
    line-height: 30px;
    margin: 0 0 30px;
    font-weight: normal;
    @media screen and (max-width: 991px){
        line-height: 26px;
        margin: 0 0 20px;
    }
}
h1, h2, h3, h4, h5, h6{ 
    font-weight: 700;
    color: $secondary-font-color;
    font-family: $headings-font-family;
}

h2{
    color: $primary-color;
    font-size: 26px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-weight: normal;
    .icon{
        font-size: 62px;
        margin-right: 20px;
    }
    @media screen and (max-width: 1860px){
        font-size: 26px;
        .icon{
            font-size: 52px;
            margin-right: 12px;
        }
    }
    @media screen and (max-width: 1600px){
        font-size: 22px;
        .icon{
            font-size: 42px;
            margin-right: 12px;
        }
    }
    @media screen and (max-width: 991px){
        font-size: 16px;
        letter-spacing: 1px;
        .icon{
            font-size: 25px;
            margin-right: 8px;
        }
    }
}

/* btn */
.btn{
    font-size: 14px;
    color:$primary-color;
    text-decoration: none;
    border: none;
    display: inline-block;
    padding: 0 30px;
    height: 50px;
    // line-height: 50px;
    font-weight: 700;
    transition: $transition-animate;
    font-family: $body-font-family;
    position: relative;
    border-radius: 30px;
    box-shadow: 0px 9px 14.28px 2.72px rgba(0, 0, 0, 0.08);
    background: $primary-font-color;
    text-transform: uppercase;
    letter-spacing: 2px;
    i{
        font-size: 14px;
        margin-left: 15px;
    }
    &:hover{
        box-shadow:none;
        color:$primary-font-color;
        background: $primary-color;
    }
    &.active{
        color:$primary-font-color;
        background: $primary-color !important;
        &:hover{
            background: $primary-font-color !important;
            color: $primary-color !important;
        }
    }
    &:focus{
        box-shadow: none !important;
    }
    &.btn-lg{
        height: 60px;
        line-height: 60px;
        font-size: 16px;
    }
    @media screen and (max-width: 1860px){
        height: 42px;
        line-height: 42px;
        padding: 0 20px;
        letter-spacing: 1.5px;
        i{
            font-size: 12px;
            margin-left: 10px;
        }
        &.btn-lg{
            height: 50px;
            line-height: 50px;
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1600px){
        font-size: 12px;
        height: 40px;
        line-height: 40px;
        i{
            font-size: 10px;
        }
    }
}
.btn-secondary:focus,.btn-primary:focus,.btn-primary:not(:disabled):not(.disabled):active{
    background-color: $primary-font-color;
    border-color: none;
    color:$primary-color;
    box-shadow: none;
}
.dark-shadow{
    box-shadow: 0px 9px 14.28px 2.72px rgba(0, 0, 0, 0.2);
}
.view-btn{
    height: 40px;
}
.view-small{
    padding: 0 16px;
}
hr,.hr{
    border-color: $border-color;
    margin: 40px 0;
    @media screen and (max-width: 1600px){
        margin: 30px 0;
    }
}

.form-control,.form-control__control{
    height: 58px;
    background: transparent;
    border: none;
    width: 100%;
    border-radius: 5px;
    color: $secondary-font-color;
    background: $primary-font-color;
    font-size: 20px;
    margin-bottom: 34px;
    padding: 10px 20px;
    resize: none;
    font-weight: 600;
    border: 1px solid transparent;
    letter-spacing: 0.5px;
    &:focus{
        box-shadow: 0px 9px 14.28px 2.72px rgba(0, 0, 0, 0.08);
        background: $primary-font-color;
        border-color: #ced7e0;
    }
    @media screen and (max-width: 1860px){
        height: 50px;
        margin-bottom: 25px;
        font-size: 15px;
    }
    @media screen and (max-width: 1600px){
        margin-bottom: 15px;
        height: 45px;
        font-size: 14px;
        padding: 10px 15px;
    }
    &.f-sm{
        font-size: 16px;
    }
    &.grey-field{
        background: #f5f5f5;
    }
    &.sm-field{
        height: 47px;
        font-size: 15px;
    }
}
.form-label-group{
    &.error-invalid{
        .form-control,.form-control__control{
            border-color: $errorColor;
        }
    }
    .error-invalid{
        .form-control,.form-control__control{
            border-color: $errorColor;
        }
    }
}
textarea{
    padding: 14px 20px;
}
select{
    &:focus{
        outline: none;
    }
}
.unselect{
    opacity: 0.5;
}
.select-custom{
    display: block;
    position: relative;
    &:after{
        content: '';
        width: 1px;
        height: 58px;
        background: #f2f2f2;
        position: absolute;
        right: 45px;
        top: 0;
        @media screen and (max-width: 1600px){
            height: 45px;
        }
    }
    select,.form-control,.form-control__control{
        background: $primary-font-color url(../../images/select-arrow.svg) no-repeat center right 15px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        border-color: transparent;
        &.form-control{
            padding-right: 40px;
        }
    }
    &.select-sm{
        &:after{
            right: 35px;
            height: 47px;
            @media screen and (max-width: 1860px){
                height: 42px;
            }
        }
        select,.form-control,.form-control__control{
            height: 47px;
            font-size: 16px;
            padding: 10px 32px 10px 14px;
            background: $primary-font-color url(../../images/select-arrow.svg) no-repeat center right 12px !important;
            background-size: 11px !important;
            @media screen and (max-width: 1860px){
                height: 42px;
                font-size: 14px;
            }
        }
    }
    &.select-grey{
        &:after{
            background: #e9e9e9;
        }
        select,.form-control,.form-control__control{
            background-color: #f5f5f5 !important;
        }
    }

    .form-control__control{
        position: relative;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        box-shadow: none !important;
        margin-bottom: 34px;
        .form-control__indicators {
            display: none;
        }
        &:hover,&:focus{
            border-color: transparent;
            box-shadow: none !important;
        }
        .form-control__value-container{
            flex-wrap: nowrap;
            padding:2px;
        }
        .form-control__placeholder{
            font-style: italic;
            font-weight: normal;
            color: $secondary-color;
            opacity: 0.5;
        }
        @media screen and (max-width: 1860px){
            margin-bottom: 25px;
        }
        @media screen and (max-width: 1600px){
            margin-bottom: 15px;
        }
    }
    
}


.form-control::-webkit-input-placeholder {
    color: $secondary-font-color;
    opacity: 0.5; 
    font-style: italic;
    font-weight: normal;
}
.form-control::-moz-placeholder {
    color: $secondary-font-color;
    opacity: 0.5; 
    font-style: italic;
    font-weight: normal;
}
.form-control:-ms-input-placeholder {
    color: $secondary-font-color;
    opacity: 0.5; 
    font-style: italic;
    font-weight: normal;
}
.form-control::-ms-input-placeholder {
    color: $secondary-font-color;
    opacity: 0.5; 
    font-style: italic;
    font-weight: normal;
}
.form-control::placeholder {
    color: $secondary-font-color;
    opacity: 0.5; 
    font-style: italic;
    font-weight: normal;
}

label{
    font-size: 16px;
    color: $secondary-font-color;
    font-family: $headings-font-family; 
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
    span{
        color: $primary-color;
    }
    sup{
        color: $primary-color;
        font-size: 16px;
    }
    @media screen and (max-width: 1860px){
        margin-bottom: 10px;
        font-size: 14px;
        letter-spacing: 1.1px;
    }
    @media screen and (max-width: 1860px){
        margin-bottom: 7px;
    }
}

.checkbox,.radiobox{
    display: inline-block;
    position: relative;
}
.checkbox input[type="checkbox"],.radiobox input[type="radio"] {
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  cursor: pointer;
   opacity: 0;
   z-index: 1;
}
.radiobox,.checkbox {
    label{
        position: relative;
        cursor: pointer;
        margin: 0;
        display: flex;
        align-items: center;
        color: $secondary-font-color;
        font-weight: normal;
        text-transform: none;
        letter-spacing: 0.6px;
        padding-left: 28px;
        font-size: 18px;
        &.f-sm{
            font-size: 16px;
        }
        @media screen and (max-width: 1860px){
            font-size: 16px;
        }
        @media screen and (max-width: 1600px){
            font-size: 14px;
            padding-left: 22px;
        }
    }
}
.checkbox {
    label{
        &:before{
            content: '';
            background:#d3d3d3;
            width: 13px;
            height: 13px;
            display: inline-block; 
            position: absolute;
            left: 0;
            top: 5px;
            @media screen and (max-width: 1600px){
                width: 12px;
                height: 12px;
                top: 4px;
            }
        }
    }
}
.checkbox {
    :checked {
        + label{
            &:after{
                content: '';
                background:$primary-color;
                width: 13px;
                height: 13px;
                display: inline-block; 
                position: absolute;
                left: 0;
                top: 5px;
                @media screen and (max-width: 1600px){
                    width: 12px;
                    height: 12px;
                    top: 4px;
                }
            }
        }
    }
}

.radiobox {
    label{
        &:before{
            content: '';
            background:#d3d3d3;
            width: 15px;
            height: 15px;
            display: inline-block; 
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 5px;
            @media screen and (max-width: 1600px){
                width: 14px;
                height: 14px;
                top: 4px;
            }
        }
    }
    :checked {
        + label{
            &:after{
                content: '';
                font-size: 38px;
                line-height: normal;
                position: absolute;
                top: 8px;
                left: 3px;
                height: 9px;
                width: 9px;
                background: $primary-color;
                border-radius: 50%;
                @media screen and (max-width: 1600px){
                    font-size: 30px;
                    height: 8px;
                    width: 8px;
                    top: 7px;
                }
            }
            &:before{
                background-color: rgba(60, 144, 165, 0.3);
            }
        }
    }
}


.breadcrumb{
    padding: 0;
    background: transparent;
    margin: 0;
    .breadcrumb-item{
        text-transform: uppercase;
        font-family: $headings-font-family;
        font-size: 16px;
        padding-left:15px;
        color: $primary-color;
        letter-spacing: 2px;
        &:before{
            content: '>';
            font-weight: 700;
            padding-right: 15px;
            color: $primary-color;
        }
        &:first-child{
            padding-left: 0;
            &::before{
                display: none;
            }
        }
        a{
            color: $primary-color;
            font-weight: 700;
            &:hover{
               opacity: 0.7;
            }
        }
        &.active{
            color: $primary-color;
            font-weight: normal;
        }
    }
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
        .breadcrumb-item{
            padding-left:10px;
            &:before{
                padding-right: 10px;
            }
        }
    }
}

.pagination {
    align-items: center;
    margin: 0;
    .page-item{
        font-family: $headings-font-family;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        border-radius: 16px;
        padding: 7px 16px;
        margin: 0 1px;
        cursor: pointer;
        transition: $transition-animate;
        line-height: normal;
        i{
            font-size: 12px;
            position: relative;
            top: -1px;
        }
        &:first-child{
            margin-right: auto;
            font-size: 14px;
            i{
                margin-right: 5px;
            }
        }
        &:last-child{
            margin-left: auto;
            font-size: 14px;
            i{
                margin-left: 5px;
            }
        }
        &.active,&:hover{
            color: $secondary-font-color;
            background: #e0e0e0;
        }
        &.disabled{
            cursor: inherit;
            background:transparent;
            opacity: 0.5;
        }
    }
    @media only screen and (max-width: 991px) {
        margin: 45px 0 0;
    }
    @media only screen and (max-width: 360px) {
        .page-item{
            i{
                display: none;
            }
        }
    }
}


.title-center{
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 60px 0;
    h2{
        justify-content: center;
        font-size: 34px;
        position: relative;
        font-weight: 700;
        background: $light-grey;
        padding: 0 35px;
        i{
            font-size: 44px;
        }
    }
    &:before{
        content: '';
        width: 100%;
        height: 2px;
        background: #dbdbdb;
        position: absolute;
        left: 0;
        top: calc(50% - 4px);
    }
    @media screen and (max-width: 1860px){
        h2{
            font-size: 24px;
            i{
                font-size: 32px;
            }
        }
    }
    @media only screen and (max-width: 1600px) {
        padding: 35px 0;
    }
}

.card{
    border-radius: 10px;
    box-shadow: 0px 8px 7.2px 0.8px rgba(0, 0, 0, 0.06);
    background: $primary-font-color;
    border: none;
}

.create-job-form .form-label-group.form-check {
    label {
        display:inline;
        word-break: break-word;
    }
}