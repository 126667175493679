@import "../../public/css/scss/variables";

.login-screen{
  height: 100vh;
  background: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  .login-logo{
    width: 382px;
    margin: 0 auto 180px;
    display: block;
  }
  .form{
    max-width: 550px;
    margin: 0 auto;
  }
  .btn{
    width: calc(100% - 80px);
    margin: 0 auto;
    display: block;
    margin-top: 100px;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    &:hover{
      background: $primary-font-color;
      color: $primary-color;
    }
  }
  .forgot-password{
    display: block;
    text-align: right;
    font-size: 16px;
    margin-left: auto;
    a{
      color: $primary-font-color;
      text-transform: uppercase;
      letter-spacing: 2px;
      &:hover{
        letter-spacing: 2.5px;
      }
    }
  }
  .invalid-feedback{
    font-size: 16px;
    font-style: italic;
    margin-top: 18px;
    letter-spacing: 1px;
    img{
      width: 20px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -7px;
    }
  }
  .password-error{
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    align-items: center;
    .invalid-feedback{
      width: 59%;
      margin: 0;
      line-height: normal;
    }
  }

  .error-login{
    font-size: 18px;
    font-style: italic;
    margin-top: 18px;
    letter-spacing: 1px;
    color: $primary-font-color;
    margin-bottom: 20px;
    img{
      width: 20px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
      margin-top: -7px;
    }
  }

  @media screen and (max-width: 1860px){
    .login-logo{
      margin: 0 auto 60px;
      width: 300px;
    }
    .form{
      max-width: 500px;
      margin: 0 auto;
    }
    .btn{
      margin-top: 50px;
      line-height: 50px;
      height: 50px;
      font-size: 14px;
    }
    .forgot-password,.invalid-feedback{
      font-size: 14px;
    }
    .invalid-feedback{
      margin-top: 10px;
      img{
        width: 15px;
        margin-right: 5px;
      }
    }
    .password-error{
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 767px){
    .login-logo{
      width: 260px;
      margin: 0 auto 50px;
    }
    .btn{
      margin-top: 30px;
    }
  }

  @media screen and (max-width: 557px){
    .password-error{
      flex-direction: column;
      align-items: flex-start;
      .invalid-feedback{
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

label{
  font-size: 18px;
  color: $primary-font-color;
  font-family: $headings-font-family; 
  @media screen and (max-width: 1860px){
      font-size: 16px;
  }
  @media screen and (max-width: 767px){
      font-size: 14px;
  }
}

.form-group{
  margin-bottom: 40px;
  @media screen and (max-width: 1860px){
    margin-bottom: 25px;
  }
}
.form-control{
  border-color: $primary-font-color;
  color: $primary-font-color;
  background: transparent;
  font-weight: normal;
  height: 68px;
  &:focus{
    background: transparent;
    border-color: $primary-font-color;
    color: $primary-font-color;
  }
  @media screen and (max-width: 1860px){
    height: 58px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 767px){
    height: 50px;
    margin-bottom: 25px;
  }
}
  
}