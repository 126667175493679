.tableCard{
    border-radius: 10px;
    box-shadow: 0px 8px 7.2px 0.8px rgba(0, 0, 0, 0.06);
    background: $primary-font-color;     
    overflow-x: auto; 
}
.table {
    .giSguV.sort::before, .giSguV.sort::after {
        right: 14px;
    }
    th,td{
        border: 1px solid $light-grey;
        padding: 20px;
        @media screen and (max-width: 1600px){
            padding: 12px;
        }
    }
    thead {
        th{
            border-bottom: 1px solid $light-grey;
            color: $secondary-font-color;
            opacity: 0.7;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 1px;
            vertical-align: middle;
            white-space: nowrap;
            background: #fafafa;
            &.sort_desc,&.sort_asc{
                opacity: 1;
            }
        }
    }
    tbody{
        td{
            font-size: 16px;
            color: $secondary-font-color;
            vertical-align: middle;
            letter-spacing: 0.6px;
            .td-icon{
                font-size: 29px;
                color: $primary-color;
            }
            .td-id{
                font-weight: 700;
                color: $primary-color;
                font-size: 18px;
                letter-spacing: 0.8px;
            }
            
            .td-large{
                font-size: 18px;
            }
            .text-strong,.text-danger{
                font-weight: 700;
            }
            .td-light{
                color: $lightFontColor;
            }
            .td-highLight{
                color: $primary-color;
            }
            .progress{
                height: 6px;
                border-radius: 0;
                margin-top: 10px;
                .progress-bar{
                    background-image: linear-gradient(-90deg, #3c90a5, #62a6b7);
                }
            }
            .small-icon{
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                box-shadow: 0px 9px 14.28px 2.72px rgba(0, 0, 0, 0.2);
                color: $primary-color;
                cursor: pointer;
                transition: $transition-animate;
                i{
                    font-size: 15px;
                }
                &:hover{
                    background: $primary-color;
                    color: $primary-font-color;
                }
                &.danger-icon{
                    background: #e32645;
                    color: $primary-font-color;
                    &:hover{
                        color: #e32645;
                        background: $primary-font-color;
                    }
                }
            }
            .td-btn-flex{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .td-stopped{
                opacity: 0.7;
            }
            @media screen and (max-width: 1600px){
                font-size: 14px;
                .td-id,.td-large{
                    font-size: 14px;
                }
                .small-icon{
                    height: 35px;
                    width: 35px;
                    i{
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.showEntries{
    padding: 0;
    margin: 0;
    li{
        display: inline-block;
        font-size: 14px;
        color: rgba(34, 34, 34, 0.702);
        letter-spacing: 0.6px;

    }
    .dropdown{
        position: relative;
        select{
            background: #e0e0e0 url(../../images/select-arrow-black.svg) no-repeat center right 12px !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background-size: 10px !important;
            cursor: pointer;
            padding: 8px 32px 8px 15px;
            border: none;   
            line-height: normal;
            font-weight: 700;
            border-radius: 16px;
            margin: 0 12px;
        }
    }
}
.table-showing-result{
    font-weight: 500;
    color: #3c90a5;
    font-size: 16px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
}
.table-pagination{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
