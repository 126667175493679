@import "../../public/css/scss/variables";
header{
    background: $primary-color;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    @media only screen and (max-width: 1200px) {
        .container{
            max-width: 100%;
        }
    }
}

/* Navbar toggle CSS */
.navbar-toggler {
    border-radius: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    outline: none;
    margin-left: 10px;
    cursor: pointer;
    display: none;
    &:focus{outline: none;}
    .navbar-toggler-icon {
        width: 28px;
        height: 24px;
        background: none;
        position: relative;
        display: inline-block;
        span {
            height: 2px;
            width: 28px;
            background-color: $primary-font-color;
            display: block;
            position: absolute;
            transition: $transition-animate;
            &:nth-child(1) {
                top: 0;
                width: 21px;
            }
            &:nth-child(2),&:nth-child(3) {
                top: 9px;
            }
            &:nth-child(4) {
                top: 18px;
            }
        }
    }
    &[aria-expanded="true"],&.active{
        .navbar-toggler-icon {
            span{
                &:nth-child(1),&:nth-child(4){
                    top: 11px;
                    opacity: 0;
                }
                &:nth-child(2){
                    transform: rotate(-45deg);
                }
                &:nth-child(3){
                    transform: rotate(45deg);
                }
            }
        } 
    }
    @media only screen and (max-width: 991px) {
        display: block;
        position: relative;
        margin-left: auto;
        &.active{
            z-index: 999;
        }
    }
}

.navbar{
    padding: 0px;
    align-items: center;
    height: 99px;
    justify-content: space-between;
    transition: $transition-animate;
    @media screen and (max-width: 1600px){
        height: 80px;
    }
    .navbar-brand{
        padding: 0;
        margin: 0;
        width:154px;
        img{
            width:154px;
        }
    }
    .navbar-collapse{
        flex-grow: 0;
        height: 100%;
        margin-left: auto;
    }
    .navbar-nav{
        align-items: center;
        height: 100%;
        .nav-item{
            height: 100%;
            .nav-link {
                color: #fff;
                padding: 0px 25px;
                width: 178px;
                position: relative;
                font-size: 12px;
                letter-spacing: 1px;
                text-transform: uppercase;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .icon{
                    display: block;
                    font-size: 34px;
                    margin: 0 0 10px;
                    opacity: 0.6;
                    transition: $transition-animate;
                }
                @media screen and (max-width: 1600px){
                    padding: 0px 15px;
                    width: 150px;
                    font-size: 11px;
                    .icon{
                        font-size: 28px;
                    }
                }
            }
            &:focus,&:hover,&.active{
                .nav-link {
                    background: rgba(255, 255, 255, 0.1);
                    .icon{
                        opacity: 1;
                    }
                }
            }
        }
    }
    
    
    @media screen and (max-width: 991px){
        height: 80px;
        .navbar-collapse {
            position: fixed;
            right: -320px;
            top: 80px;
            z-index: 99;
            background-color: $primary-color;
            width: 320px;
            height: calc(100vh - 80px) !important;
            overflow-y: auto;
            transition: $transition-animate;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            &.show{
                right: 0;
            }
        }
        .navbar-nav{
            justify-content: center;
            width: 100%;
            height: auto;
            padding: 50px 0;
            align-items: flex-start;
            .nav-item{
                height: auto;
                width: 100%;
                .nav-link{
                    height: auto;
                    width: 100%;
                    padding: 20px 24px;
                    font-size: 16px;
                    font-weight: 300;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    .icon{
                        margin: 0 12px 0 0;
                        font-size: 26px;
                    }
                }
            }
        }
        .navbar-brand{
            width: 125px;
            img{
                width: 125px;
            }
        }
    }
    @media screen and (max-width: 640px){
        .navbar-nav{
            .nav-item{
                margin: 5px 0;
                .nav-link{
                    font-size: 16px;
                }
            }
        }
    }
}

.header-login{
    min-width: 160px;
    border-left: 2px solid rgba(255, 255, 255,0.2);
    padding: 10px 0 10px 30px;
    margin-left: 50px;
    .dropdown-toggle{
        display: block;
        position: relative;
        color: rgba(255, 255, 255,0.8);
        font-weight: 700;
        letter-spacing: 0.6px;
        font-size: 14px;
        cursor: pointer;
        padding-right: 15px;
        &:after{
            position: absolute;
            right: 0;
            top: 8px;
            border-top: 6px solid;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            color: $primary-font-color;
        }
        .user-name{
            display: inline-block;
            // width: 130px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
        }
        .position{
            display: block;
            font-weight: normal;
            font-size: 12px;
            text-transform: uppercase;
            color: rgba(255, 255, 255,0.6);
            letter-spacing: 1px;
        }
        &:hover{
            color: $primary-font-color;
        }
    }
    .dropdown-menu{
        background: $primary-font-color;
        margin-top: 16px;
        text-align: center;
        padding: 0;
        border: none;
        left: -10px !important;
        border-radius: 10px;
        box-shadow: rgba(34, 34, 34, 0.2) 0px 5px 15px;
        &:before {
            position: absolute;
            content: "\f0de";
            font-size: 22px;
            font-family: "FontAwesome";
            top: -14px;
            right: 20px;
            color: rgb(255, 255, 255);
        }
        button,a{
            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            letter-spacing: 1.5px;
            color: $primary-color;
            padding: 10px;
            &:hover,&:focus{
                background: transparent;
            }
        }
        .dropdown-divider{
            border-color: rgba(255, 255, 255,0.2);
        }
    }
    @media screen and (max-width: 1600px) {
        min-width: 150px;
        padding: 10px 0 10px 20px;
        margin-left: 15px;
        border-left: 1px solid rgba(255, 255, 255,0.2);
        
        .dropdown-menu{
            left: -20px !important;
        }
    }
    @media screen and (max-width: 991px){
        .dropdown-menu{
        margin-top: 22px;
        right: 0;
        margin-left: -40px !important;
        }
    }
    @media screen and (max-width: 575px){
        min-width: inherit;
        padding: 4px 0 4px 15px;
        .dropdown-toggle {
            font-size: 14px;
            .position{
                font-size: 8px;
            }
        }
    }
}