$body-bg: #f0f0f0 !default;

//Fonts settings
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

$body-font-family: 'Roboto', sans-serif;
$headings-font-family: 'Roboto', sans-serif;

// Colors
$primary-color: #3c90a5;
$secondary-color: #222222;

// font colors
$primary-font-color: #fff; //white
$secondary-font-color: #222222; //normal dark
$dark-blck: #e6e6e6; //dark
$light-grey: #f0f0f0;
$lightGreyTwo: #fafafa;
$lightFontColor: #666666;
$errorColor: #dc3545;

// border colors
$border-color: rgba(34, 34, 34, 0.1);

// transition
$transition-animate: all ease 0.4s;

// DEVICES
$s-device: 575px;
$m-device: 767px;
$l-device: 991px;
$xl-device: 1200px;

$xxl-device: 1599px;
$xxl-min-device: 1600px;
