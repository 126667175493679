@import "../../public/css/scss/variables";
footer{
    background: #222222;
    text-align: center;
    .footer-copyright{
        height: 112px;
        display: flex;
        align-items: center;
        p{
            color: rgba(255, 255, 255, 0.2);
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin: 0;
            a{
                color: rgba(255, 255, 255, 0.2);
                &:hover{
                    color: $primary-color;
                }
            }
        }
        @media screen and (max-width: 1199px){
            height: 60px;
        }
    }
}