@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?vj8d8n');
  src:  url('./fonts/icomoon.eot?vj8d8n#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?vj8d8n') format('truetype'),
    url('./fonts/icomoon.woff?vj8d8n') format('woff'),
    url('./fonts/icomoon.svg?vj8d8n#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email-light:before {
  content: "\e91e";
}
.icon-zoom:before {
  content: "\e91d";
}
.icon-new-job:before {
  content: "\e900";
}
.icon-progress:before {
  content: "\e901";
}
.icon-report:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-source-file:before {
  content: "\e904";
}
.icon-source-file-sm:before {
  content: "\e905";
}
.icon-apply:before {
  content: "\e906";
}
.icon-autominer-logo .path1:before {
  content: "\e907";
  color: rgb(0, 0, 0);
}
.icon-autominer-logo .path2:before {
  content: "\e908";
  margin-left: -8.5em;
  color: rgb(0, 0, 0);
}
.icon-autominer-logo .path3:before {
  content: "\e909";
  margin-left: -8.5em;
  color: rgb(0, 0, 0);
}
.icon-autominer-logo .path4:before {
  content: "\e90a";
  margin-left: -8.5em;
  color: rgb(35, 31, 32);
}
.icon-autominer-logo .path5:before {
  content: "\e90b";
  margin-left: -8.5em;
  color: rgb(35, 31, 32);
}
.icon-autominer-logo .path6:before {
  content: "\e90c";
  margin-left: -8.5em;
  color: rgb(35, 31, 32);
}
.icon-cancel:before {
  content: "\e90d";
}
.icon-completed:before {
  content: "\e90e";
}
.icon-double-arrows:before {
  content: "\e90f";
}
.icon-download:before {
  content: "\e910";
}
.icon-email:before {
  content: "\e911";
}
.icon-error-red .path1:before {
  content: "\e912";
  color: rgb(229, 17, 67);
}
.icon-error-red .path2:before {
  content: "\e913";
  margin-left: -1.0966796875em;
  color: rgb(255, 255, 255);
}
.icon-error-red .path3:before {
  content: "\e914";
  margin-left: -1.0966796875em;
  color: rgb(255, 255, 255);
}
.icon-error-white:before {
  content: "\e915";
  color: #fff;
}
.icon-file-scanned:before {
  content: "\e916";
}
.icon-filter:before {
  content: "\e917";
}
.icon-help:before {
  content: "\e918";
}
.icon-nav-completed:before {
  content: "\e919";
}
.icon-nav-dashboard:before {
  content: "\e91a";
}
.icon-nav-progress:before {
  content: "\e91b";
}
.icon-nav-search:before {
  content: "\e91c";
}
